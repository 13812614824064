import {ERROR_MESSAGES, RESEARCH_TABS, S3_IMAGE_BASE_URL, REMOVED_ENTITIES} from "../../utils/constants";
import {downloadGraphUrl} from "../../main";
import {getSocialProfileIcon} from "@/utils/functions";
import {getNodeConnections} from "@/services/research.js";

// containers imports
import DarkWeb from "@/containers/cards/dark-web";
import SearchEngineResult from "@/containers/cards/search-engine-result";
import Website from "@/containers/cards/website";
import ImagePreview from "@/containers/image-preview";

const GreenContent = () => import("@/containers/cards/green-content");
const NeoKeyFacts = () => import("@/containers/cards/key-facts");
const SocialProfile = () => import("@/containers/cards/social-profile");
const AssociatedPeople = () => import("@/containers/cards/associated-people");
const noteCard = () => import("@/containers/cards/note");
const Media = () => import("@/containers/cards/media");
const SocialPost = () => import("@/containers/social-post");
const NeoSetStatus = () => import("@/containers/set-status");
const PersonalDetailsTab = () => import("@/containers/personal-details-tab");
const ModalAddContent = () => import("@/components/modal-add-content");

// components imports
import ErrorMessage from "@/components/error-message";
import {mapGetters} from "vuex";
import nLoader from "@/components/n-loader";

const ClipboardTable = () => import("@/components/clipboard-table");
const Tags = () => import("@/components/tags");
const Input = () => import("@/components/input");
const Button = () => import("@/components/button");
const NeoToggle = () => import("@/components/toggle");
const VueMultiselect = () => import("@/components/vue-multiselect");

import axios from "@/axios";
import rawAxios from "axios";
import {EventBus} from "@/main.js";
import {patchKeyfacts, getKeyfacts} from "@/services/end-points/keyfacts.data-provider";

// filepond imports
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";

import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import Radios from "@/components/radios";

import {v4 as uuidv4} from "uuid";
import {cloneDeep, isEqual} from "lodash";
const NotesEditor = () => import("./editor.vue");

// import NotesEditor from "./editor.vue";

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginPdfPreview);
// FilePond.render

export default {
    name: "neo-sidepanel",
    components: {
        // NotesEditor,
        "neo-tags": Tags,
        "neo-social-post": SocialPost,
        "neo-dark-web": DarkWeb,
        "neo-media": Media,
        "neo-website": Website,
        "neo-green-content": GreenContent,
        "neo-associated-people": AssociatedPeople,
        "neo-search-engine-result": SearchEngineResult,
        "neo-social-profile": SocialProfile,
        "neo-error-message": ErrorMessage,
        "image-preview": ImagePreview,
        "neo-note-card": noteCard,
        "neo-personal-details": PersonalDetailsTab,
        FilePond,
        "neo-input": Input,
        "neo-button": Button,
        "clipboard-table": ClipboardTable,
        VueMultiselect,
        NeoSetStatus,
        NeoKeyFacts,
        nLoader,
        "neo-radio": Radios,
        NeoToggle,
    },
    props: ["type", "card", "currentTab", "panelWidth", "searchedText", "options", "isKeyFactLoading"],
    data() {
        return {
            allEntities: [],
            removedEntity: ["associated_entity", "social_profile", "volunteering_roles", "wider_evidence_skills", "charitable_work", "active_media", "search", "passive_media"],
            errors: ERROR_MESSAGES,
            tabsStat: RESEARCH_TABS.map((rTab) => rTab.key).reduce((map, tab) => ({...map, [tab]: false}), {}),
            translate: false,
            expandAll: false,
            caret: "caret-down",
            activeNoteType: null,
            previewFile: "",
            openImagePreview: false,
            openImageURL: "",
            showExpand: false,
            myFiles: "",
            myServer: {
                process: this.uploadScreenShot,
                load: this.loadScreenshot,
                revert: this.revereImage,
            },
            tabs: {},
            searchTweetId: "",
            extraContentCount: 3,
            extraContentIncrementCounter: 3,
            relation: {
                entity: [],
                type: [],
            },
            line_options: [
                {
                    value: "solid",
                    icon: "icons_solid.svg",
                    description: "Solid",
                },
                {
                    value: "dashed",
                    icon: "icons_dotted.svg",
                    description: "Dashed",
                },
                {
                    value: "dotted",
                    icon: "icons_dotted.svg",
                    description: "Dotted",
                },
            ],
            line_direction: [
                {
                    value: "arrow_from",
                    icon: "icons_from.svg",
                    description: "Arrow from related entity to new entity",
                },
                {
                    value: "arrow_to",
                    icon: "icons_to.svg",
                    description: "Arrow from new entity to related entity",
                },
                {
                    value: "arrow_bi",
                    icon: "icons_bi-dir.svg",
                    description: "Bi-Directional arrow between new and related entity",
                },
                {
                    value: "arrow_none",
                    icon: "icons_non-dir.svg",
                    description: "Non-Directional line between new and related entity",
                },
            ],
            relations: [],
            sourcesOptions: [
                {
                    group: "Sources",
                    categories: ["Social Media", "Open Source", "Risk Database", "Corporate Database", "Court Records"],
                },
            ],
            selected_source: this.card?.source_categories && this.card?.source_categories?.length > 0 ? this.card.source_categories : [],
            selected_risk_categories: this.card?.risk_categories && this.card?.risk_categories?.length > 0 ? this.card.risk_categories.map((ele) => ({category: ele})) : [],
            showRelationships: true,
            graphLabel: "",
            add_graph: false,
            isRelationLoading: false,
            radioOptions: ["Red", "Amber"],
            entity_status: this.card?.entity_status && this.card?.risk_categories?.length > 0 ? this.card?.entity_status : "Red",
            showNotes: true,
            oldGraphValue: "",
            isNotesOpen: false,
        };
    },
    computed: {
        ...mapGetters(["getConfirmedCount", "getConfirmed", "getOriginalCaseData", "getIrrelevantCount", "getIrrelevant", "getSocialPlatforms", "getReadOnlyMode", "popIrrelevant", "getExtraContent", "getCaseId", "getMatchingProfile"]),

        tags() {
            return this.$store.getters.getMatchingProfileConsolidatedFlags.categories.map((tag) => ({tag}));
        },
        gotApiError() {
            let matchingData = this.$store.getters.getMatchingProfile;
            let data = this.$store.getters.getProfileData.find((el) => el.platform === "twitter" && el?.username === matchingData?.username);
            if (data && data?.got_api_error) return data?.got_api_error;
            else return false;
        },
        profile() {
            return this.$store.getters.getMatchingProfile;
        },
        extra_content() {
            return this.$store.getters.getMatchingExtraContent?.slice(0, this.extraContentCount)?.map((content) => ({...content, entity_status: this.card.entity_status})) ?? [];
        },
        getProfile_for_report() {
            if (this.$store.getters.getMatchingProfile) return this.$store.getters.getMatchingProfile.data.filter((item) => item.include_in_report);
            else return [];
        },
        profileImage() {
            return "/case-screenshot/" + this.$store.getters.getCaseId + "/" + this.$store.getters.getMatchingCaseData.screenshot.location;
        },
        getNotes() {
            let notes = [];
            if ("notes" in this.card) {
                notes.push({title: "Notes", desc: this.card.notes, key: "notes"});
            }
            if ("customer_notes" in this.card) {
                notes.push({title: "Customer Notes", desc: this.card.customer_notes, key: "customer_notes"});
            }
            return notes;
        },
        getTabLabel() {
            return RESEARCH_TABS.filter((el) => el.key === this.currentTab)[0].name;
        },

        getIcon() {
            if (this.currentTab === "socialProfiles") {
                let currentPlatform = this.$store.getters.getSocialPlatforms.filter((elem) => elem.key == this.card.platform);
                if (currentPlatform.length > 0) {
                    return currentPlatform[0].icon_data;
                }
            } else if (this.currentTab === "associatedPeople") {
                let icondata = this.$store.getters.getIconsData.filter((elem) => elem.key == this.card.entity_identifier);
                if (icondata.length > 0) return icondata[0].icon_data;
            } else if (this.currentTab === "greenContent") {
                let icondata = this.$store.getters.getIconsData.filter((elem) => elem.key == this.card.type);
                if (icondata.length > 0) return icondata[0].icon_data;
            } else if (this.currentTab === "websites") {
                let icondata = this.$store.getters.getIconsData.filter((elem) => elem.key == "website");
                if (icondata.length > 0) return icondata[0].icon_data;
            }
            return require("@/assets/icons/documents.svg");
        },

        getLabel() {
            let label = {format: true, text: ""};
            if (this.currentTab === "socialProfiles") {
                label.text = this.card.platform;
            } else if (this.currentTab === "associatedPeople") {
                label.text = this.card.entity_type.graph_label;
            } else if (this.currentTab === "greenContent") {
                label.text = this.card.type;
            } else if (this.currentTab === "websites") {
                label.text = "Website";
            } else if (this.currentTab === "media") {
                label.format = false;
                label.text = this.card.url;
            } else if (this.currentTab === "keyFacts") {
                label.text = this.card.key_fact_name;
            }

            return label;
        },
        showOutputs() {
            return this.currentTab === "keyFacts";
        },
        requireScreenshot() {
            if (this.showOutputs) {
                return false;
            } else if (this.currentTab === "socialProfiles") {
                return this.getSocialPlatforms.find((el) => el.key === this.card.platform)?.require_screenshot_in_report ?? false;
            } else {
                return true;
            }
        },
        getDesc() {
            if (this.currentTab === "socialProfiles") {
                return this.card.username;
            } else if (this.currentTab === "media") {
                return;
            } else {
                return this.card.entity;
            }
        },
        getPersonalDetailsContent() {
            return this.$store.getters.getIrrelevant.personalDetails.map((card) => {
                return card.item;
            });
        },

        getExpandHeight() {
            let tabHeight = this.$refs.sectionnav[0].offsetHeight;
            let height = this.$refs.trashcontainer.offsetHeight - (tabHeight + 8) * this.$refs.sectionnav.length;
            return `${height}px`;
        },

        getIrrelevantData() {
            const extraContentData = this.getOriginalCaseData?.extra_content
                .filter((n) => n.deleted)
                .map((ele) => ({
                    case_id: this.getCaseId,
                    component: "neo-media",
                    tab: "extraContent",
                    type: "IRRELEVANT",
                    item: ele,
                    name: "Extra Content",
                }));
            let data = this.getIrrelevant;
            let irrelevantData = {};
            for (const [tab, tabData] of Object.entries(data)) {
                if (tab !== "personalDetails") {
                    irrelevantData[tab] = tabData;
                } else {
                    irrelevantData[tab] = [];
                    tabData.forEach((item) => {
                        if (item.item && item.item.entity_identifier) {
                            if (!REMOVED_ENTITIES.includes(item.item.entity_identifier)) {
                                irrelevantData[tab].push(item);
                            }
                        }
                    });
                }
            }
            irrelevantData.extraContent = extraContentData;
            return irrelevantData;
        },
        getHtmlText() {
            let modifiedText = [];
            let newText = this.card.raw_page_text;
            let re = new RegExp(this.searchedText, "gi");
            this.card.raw_page_text.match(re)?.forEach((matchedText) => {
                if (!modifiedText.includes(matchedText)) {
                    let matchedRe = new RegExp(matchedText, "g");
                    newText = newText.replace(matchedRe, `<mark>${matchedText}</mark>`);
                    modifiedText.push(matchedText);
                }
            });
            // let newText = this.card.raw_page_text.replace(re, `<mark>${this.searchedText}</mark>`);
            return newText;
        },
        outputDesc() {
            let output = this.card.outputs;
            if (this.isRelationLoading) {
                return "Please wait...";
            }
            if (!output) {
                output = this.card.output_notes;
            }
            return output;
        },
        ragStatusCheck() {
            let categoriesList = this.options.riskCategories.find((cat) => cat.group === "Risk Categories");
            let radio = false;
            if (categoriesList?.categories) {
                radio = categoriesList?.categories.some((elem) => this.selected_risk_categories.some((cat) => cat.category === elem.category));
            }
            return this.selected_risk_categories.length > 0 && !this.getReadOnlyMode && radio;
        },
        getFetchedTweet() {
            return this.$store.getters.getMatchingSocialData?.data?.length || false;
        },
        getTotalTweetCount() {
            return this.$store.getters.getMatchingSocialData?.tweet_count || false;
        },
    },

    async mounted() {
        if (this.card.screenshot && this.card.screenshot.available) {
            this.myFiles = [{source: this.card.screenshot.location, options: {type: "local"}}];
        } else {
        }

        this.tabs = RESEARCH_TABS.map((tab) => {
            let obj = {};
            obj[tab.key] = tab.name;
            return obj;
        }).reduce((doc, currentTab) => {
            doc = {...doc, ...currentTab};
            return doc;
        });

        this.tabs.extraContent = "Extra Content";
        this.tabsStat.extraContent = false;

        if (this.currentTab === "keyFacts") {
            this.isRelationLoading = true;
            this.graphLabel = this.card.graph_label;
            this.add_graph = this.card.add_graph;
            await this.getAllEntities();
            // this.relations = this.card.relations.map((ele) => ({ ...ele, entity: this.allEntities.find((n) => n._id == ele.entity) }));

            const id = this.card._id || this.$route.params.caseid;
            const {data} = await axios.get(`/key-facts/key-fact/${id}`);
            const matchedkeyFactById = data?.data;
            this.$set(this.card, "outputs", matchedkeyFactById?.output_notes);
            this.isRelationLoading = false;
        }
        let container = this.$refs.container;

        // const keyFacts = await getKeyfacts(this.getCaseId);
        // const keyFacts = await getKeyfacts(this.getCaseId);
        // const matchedkeyFactById = keyFacts.find(keyfact => keyfact._id === this.$route.params.caseid);
        // console.log('matchedkeyFactById :>> ', this.card);
        // this.card = matchedkeyFactById
        // this.$emit("dataChanges", this.card, matchedkeyFactById, this.currentTab);

        let media = this.$refs.media?.children[0];
        if (media?.offsetWidth) this.extraContentIncrementCounter = parseInt(1 / (media.offsetWidth / container.offsetWidth), 10);

        this.handleScroll();
    },

    methods: {
        async getAllEntities() {
            this.allEntities = [];
            let entities = await getNodeConnections(this.getCaseId);
            this.allEntities = entities?.entities;
        },

        getOptionIcon(option) {
            if (option.entity_type == "social_profile") {
                return this.$store.getters.getSocialPlatforms.find((e) => e.key == option.platform).icon_data;
            } else {
                try {
                    return this.$store.getters.getIconsData.find((e) => e.key == option.entity_identifier).icon_data;
                } catch {
                    return require("@/assets/add.svg");
                }
            }
        },

        handleScroll() {
            let el = this.$refs.container;
            if (el.scrollTop > el.scrollHeight - el.offsetHeight - 100) {
                this.extraContentCount = this.extraContentCount + this.extraContentIncrementCounter;
            }
        },
        async onFetchTweet() {
            let url = `${process.env.VUE_APP_PORTAL_URL}/orca/api/get/tweet/by/tweetid`;
            try {
                let response = await rawAxios.post(url, {
                    case_id: this.$store.getters.getCaseId,
                    tweet_id: this.searchTweetId,
                    username: this.card.username,
                });
                let data = [];
                data.push(response.data);
                // this.$store.dispatch("updateMatchingProfile", response);
                this.$store.commit("UPDATE_MATCHING_PROFILE", data);
            } catch (error) {
                console.error(error);
            }
        },
        getAvatar() {
            let icon = "";
            if (this.currentTab === "socialProfiles") {
                icon = getSocialProfileIcon(this.card.platform);
            }

            return icon;
        },
        removeImage() {
            this.showExpand = false;
            let changes = {
                available: false,
                file_mime_type: "",
                file_name: "",
                location: "",
                size: 0,
            };
            this.$emit("dataChanges", this.card, {...this.card, screenshot: changes}, this.currentTab);
        },
        getArrowIcon(icon) {
            return require(`@/assets/icons/arrow-icons/${icon}`);
        },
        revereImage() {
            this.showExpand = false;
            this.openImagePreview = false;
        },

        async expandImagePreview() {
            if (this.card.screenshot.file_mime_type === "application/pdf") {
                window.open(`${process.env.VUE_APP_IMAGES_URL}/${this.card.screenshot.location}`);
            } else {
                const url = this.imgUrl;
                this.previewFile = `${downloadGraphUrl}/${this.card.screenshot.location}`;
                this.openImagePreview = true;
            }
        },
        async uploadScreenShot(fieldName, file, metadata, load, error, progress, abort) {
            const formData = new FormData();
            formData.append("file", file, file.name);
            try {
                let response = await axios({
                    url: "/uploads/reports/images",
                    method: "post",
                    data: formData,
                    onUploadProgress: (e) => {
                        // updating progress indicator
                        progress(e.lengthComputable, e.loaded, e.total);
                    },
                });
                load(response.data.file);
                this.showExpand = true;
                // const caseData = { ...this.card };
                this.card.is_collapse = "is_collapse" in this.card ? this.card.is_collapse : false;
                this.card.screenshot = {
                    available: true,
                    location: response.data.file,
                    file_name: response.data.file,
                    file_mime_type: file.type,
                    size: file.size,
                };
                this.openImageURL = `${process.env.VUE_APP_IMAGES_URL}/${this.card.screenshot.location}`;
                this.$emit("dataChanges", this.card, this.card, this.currentTab);
            } catch (error) {
                console.error("Request canceled", error);
            }
        },

        async onNotesChange(changedNote, type) {
            if (type) this.activeNoteType = type;
            if (this.activeNoteType) {
                if (this.activeNoteType == "outputs") {
                    this.card["output_notes"] = changedNote;
                } else {
                    this.card[this.activeNoteType] = changedNote;
                }
            } else {
                this.card.notes = changedNote;
            }
            this.$emit("dataChanges", this.card, this.card, this.currentTab);
            if (this.activeNoteType == "outputs") await patchKeyfacts({id: this.card._id, case_id: this.getCaseId, output_notes: changedNote});
            if (this.activeNoteType == "notes") await patchKeyfacts({id: this.card._id, case_id: this.getCaseId, notes: changedNote});
            if (this.activeNoteType == "customer_notes") await patchKeyfacts({id: this.card._id, case_id: this.getCaseId, customer_notes: changedNote});

            this.onCloseRichTextEditor();
        },
        async loadScreenshot(source, load, error, progress, abort, headers) {
            try {
                //this.openImageURL = `${S3_IMAGE_BASE_URL}/${source}`
                this.openImageURL = `${process.env.VUE_APP_IMAGES_URL}/${source}`;
                let response = await rawAxios.get(this.openImageURL, {responseType: "blob"});
                load(response.data);
                this.showExpand = true;
            } catch (error) {
                this.openImageURL = "";
                load();
            }
        },
        async handleFilePondInit() {
            // this.$refs.pond.addFile(await this.loadScreenshot())
        },
        async onDataChanges(card, changes) {
            // alert()
            changes = cloneDeep(changes);
            if (changes.status === null && changes.tab !== "extraContent") {
                this.$store.dispatch("popIrrelevant", {item: card, tab: changes.tab, currentTab: this.currentTab});
            }
            if (changes.tab !== "extraContent") {
                this.$store.dispatch("updateCard", {
                    tab: changes.tab,
                    card: card,
                    changes,
                });
            }
            let case_id = this.$store.getters.getCaseId;
            let tab = changes.tab;

            let urlComponentTypeMap = {
                associatedPeople: {
                    url: "/entity-identifier",
                },
                socialProfiles: {
                    url: "/add-social-profile",
                },
                personalDetails: {
                    url: "/entity-identifier",
                },
                searchEngineResults: {
                    url: "/google-search-data",
                },
                greenContent: {
                    url: "/add-green-content",
                },
                websites: {
                    url: "/add-website",
                },
                media: {
                    url: "/save-adverse-media",
                },
                darkWeb: {
                    url: "",
                },
                extraContent: {
                    url: `/extra-content-analysis/${case_id}`,
                },
                keyFacts: {
                    url: "/key-facts",
                },
            };

            let data = {};

            if (tab === "greenContent") {
                data = {
                    case_id: case_id,
                    is_collapse: changes.is_collapse,
                    url: changes.value,
                    data_type: changes.type,
                    id: changes._id,
                };
            }

            if (tab === "associatedPeople") {
                let id = changes._id;
                let obj = {...changes};
                obj.key_facts = obj?.key_facts?.map((ele) => ({key_fact_id: ele._id}));
                delete obj._id;
                obj.id = id;
                data = obj;
                data.is_collapse = changes.is_collapse || false;
            }

            if (tab === "websites") {
                data = {
                    case_id: case_id,
                    is_collapse: false,
                    url: changes.website,
                    data_type: changes.type,
                    id: changes._id,
                };
            }
            if (tab === "socialProfiles") {
                // let obj = { ...changes };
                data = {
                    ...changes,
                    ...{
                        case_id: case_id,
                        is_collapse: false,
                        username: changes.username,
                        platform: changes.platform,
                        id: changes._id,
                    },
                };
                data.key_facts = data?.key_facts?.map((ele) => ({key_fact_id: ele._id}));
            }

            if (tab === "searchEngineResults") {
                data = {
                    case_id: case_id,
                    is_collapse: false,
                    url: changes.link,
                    id: changes._id,
                };
            }

            if (tab === "media") {
                let obj = {...changes};
                delete obj.categories;
                obj.key_facts = obj?.key_facts?.map((ele) => ({key_fact_id: ele._id}));
                data = obj;
                data.is_collapse = changes.is_collapse || false;
            }

            if (tab === "personalDetails") {
                let obj = {...changes};
                obj.id = obj._id;
                obj.key_facts = obj?.key_facts?.map((ele) => ({key_fact_id: ele._id}));
                delete obj._id;
                data = obj;
                data.is_collapse = changes.is_collapse || false;
            }

            if (tab == "extraContent") {
                const deleteContent = {deleted: false};
                this.$store.dispatch("updateExtraContent", {
                    content: changes,
                    ...deleteContent,
                });
                data = {
                    categories: changes.categories,
                    content_id: changes._id,
                    deleted: false,
                };
            }

            if (tab === "keyFacts") {
                data = {...changes};
                data.id = data._id;
                delete data._id;
            }

            data.status = changes.status;
            data.entity_status = this.ragStatusCheck ? data?.entity_status || "Red" : "";
            try {
                await axios.put(urlComponentTypeMap[tab]["url"], data);
            } catch (err) {
                console.error(err);
            }

            if (this.$route.name === "research-automated") {
                EventBus.$emit("refreshResearchScreen");
            }
        },

        onToggle(section) {
            for (const [k, v] of Object.entries(this.tabsStat)) {
                if (k === section) {
                    this.tabsStat[k] = !this.tabsStat[k];
                } else {
                    this.tabsStat[k] = false;
                }
            }
            this.$forceUpdate();
        },
        addRelation() {
            this.showRelationships = true;
            this.relations.push({
                elem: null,
                deleted: false,
            });
        },
        setLineType(val, relation) {
            relation.line_type = val;
        },
        setArrowType(val, relation) {
            relation.arrow_type = val;
        },
        deleteRelation(relation, idx) {
            relation.deleted = true;
            // this.relations.splice(idx, 1)
        },
        handleSave() {
            relations = this.relations;
        },
        socialPostCheck(data) {
            return this.getReadOnlyMode ? data.include_in_report && data.flagged : data.flagged;
        },
        async onCopyToClipBoard() {
            let el = this.$refs.clipboardTableContainer;
            el.style.display = "block";
            let range, sel;
            if (document.createRange && window.getSelection) {
                range = document.createRange();
                sel = window.getSelection();
                sel.removeAllRanges();
                try {
                    range.selectNodeContents(el);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(el);
                    sel.addRange(range);
                }
                document.execCommand("copy");
            } else if (body.createTextRange) {
                range = body.createTextRange();
                range.moveToElementText(el);
                range.select();
                range.execCommand("Copy");
            }

            el.style.display = "none";
            this.$toast.success("Copied tweet table to clipboard.");
        },

        handleUpdateSource(value, key) {
            const cardCheck = isEqual(this.card[key], value);
            this.card[key] = value;
            this.card.entity_status = this.ragStatusCheck ? this.card?.entity_status || "Red" : "";
            if (!cardCheck || key === "status") {
                this.$emit("dataChanges", this.card, this.card, this.currentTab);
            }
        },
        saveGraphLabel() {
            this.card.add_graph = this.add_graph;
            // this.card.graph_label = this.graphLabel;
            this.$emit("dataChanges", this.card, this.card, this.currentTab);
        },
        onCloseRichTextEditor() {
            this.$modal.hide("notes-editor-modal");
        },
        openRichTextEditor(key) {
            // this.$modal.show("ck-editor-modal", { card: this.card, key: key, currentTab: this.currentTab });
            this.activeNoteType = key;
            // this.activeContent = t
            this.$modal.show("notes-editor-modal", {card: this.card, key: key, currentTab: this.currentTab, noteKey: this.activeNoteType});
        },

        async handleUpdateRelations() {
            // alert(1)

            const social_profiles = this.relations.filter((ele) => ele.elem?.entity_type === "social_profile" && !ele.deleted).map((e) => e.elem._id);
            const media = this.relations.filter((ele) => ele.elem?.entity_type === "passive_media" && !ele.deleted).map((e) => e.elem._id);
            const entities = this.relations.filter((ele) => typeof ele.elem?.entity_type === "object" && !ele.deleted).map((e) => e.elem._id);
            const key_fact_id = this.card._id;
            await axios.put("/key-facts/entity", {
                social_profiles,
                media,
                entities,
                key_fact_id,
            });
            await this.$emit("more", this.card);
            this.$toast.success("Updated successfully.");
            this.relations = [];
            this.$emit("refreshData");
        },

        async handleRagStatus() {
            this.card.entity_status = this.entity_status;
            this.$emit("dataChanges", this.card, this.card, this.currentTab);
            // this.$emit("refreshData", "keyFacts");
            this.$emit("onTabChange", this.currentTab);
        },

        handleRagStatusUpdate() {
            if (this.selected_risk_categories.length === 0) {
                this.entity_status = "";
                this.card.entity_status = "";
                this.handleRagStatus();
            } else if (this.selected_risk_categories.length != 0 && this.entity_status === "") {
                this.entity_status = "Red";
                this.card.entity_status = "Red";
                this.handleRagStatus();
            }
        },
        cardsCheck(cards) {
            if (this.getReadOnlyMode) {
                return cards?.filter((e) => e.status === "CONFIRMED")?.length > 0;
            } else {
                return cards?.length > 0;
            }
        },
        handleGraphValue() {
            this.card.graph_label = this.graphLabel;
            if (this.oldGraphValue != this.graphLabel.trim()) this.$emit("dataChanges", this.card, this.card, this.currentTab);
        },
        onRagChanges(ragstatus) {
            this.card.entity_status = ragstatus;
            this.$emit("dataChanges", this.card, this.card, this.currentTab);
            // this.$emit("onTabChange", "socialProfiles");
            // this.$emit("onTabChange", "keyFacts");
        },
        onTagChanges(categories) {
            let cats = categories.map((e) => e.tag);
            this.$emit("dataChanges", this.card, {...this.card, risk_categories: cats}, this.currentTab);
        },
        onAddContent() {
            this.$store.dispatch("matchingProfile", {
                username: this.card.username,
                platform: this.card.platform,
            });
            this.$modal.show(
                ModalAddContent,
                {
                    text: "This text is passed as a property",
                    heading: "Add Content Analysis",
                    datatype: "missing",
                    card: this.card,
                },
                {
                    height: "auto",
                    width: "40%",
                    styles: {
                        overflow: "inherit",
                    },
                },
                {
                    "before-close": (event) => {},
                    tagChange: (tagChange) => {},
                    ragChange: (ragStatus) => {},
                }
            );
        },
    },
};
